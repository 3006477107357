import jwtDecode from 'jwt-decode';

import {
  SIGN_UP, SIGN_IN, SIGN_OUT, USER_LOADED
} from '../../types';

const initialState = {
  token: localStorage.getItem('token'),
  name: null,
  email: null,
  _id: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN:
    case SIGN_UP:
    case USER_LOADED:
      const user = jwtDecode(action.token);
      return {
        ...initialState,
        token: action.token,
        name: user.name,
        email: user.email,
        _id: user._id,
      };
      
    case SIGN_OUT:
      localStorage.removeItem('token');
      return {
        token: null,
        name: null,
        email: null,
        _id: null,
      };
    default:
      return state;
  }
};

export default authReducer;
